import React from "react";
import "../App.css";
import logo from "../images/wewadlogo.png";
import footerunderline from "../images/footer_underline.png";
import footerarrow from "../images/footerarrow.png";
import footerbgimg from "../images/footerbgimg.jfif";
import femalememoji from "../images/femalememoji.png";
import { Link } from "react-router-dom";
import newlogo from "../images/logooo.png";

function Footer() {
  return (
    <div>
      <footer className="p-md-5 p-3 ">
        <footer className="pb-3 rounded-sm">
          <div
            className="homeSegment8 p-md-5"
            style={{
              backgroundImage: `url(${footerbgimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              height: "400px",
              backgroundColor: "rgb(0 0 0 / 5%)",
              position: "relative",
            }}
          >
            <div className="homesection3heading footersection3heading pt-5">
              Ready to Make a Difference?
            </div>
            <div className="homesection3subheading pb-4">
              Join us in empowering women and individuals with disabilities
            </div>

            <div className=" footerbtnflex">
              <div className="footerbtnpara pt-2 pe-2">
                <span>
                  <img src={femalememoji} alt="" />
                </span>{" "}
                Click here to donate now and help level up the lives of women
                and the disbaled
              </div>
              <a href="/donate">
                <button className="joinusbtn">
                  <span className="pe-2">Donate Now</span>{" "}
                </button>
              </a>
            </div>
          </div>
          <div className="footersection px-md-5  ">
            <div className="footersection1">
              <a href="/" className="footerlogo">
                <img src={newlogo} alt="logo" className="footerlogoo" />
              </a>
              <div className="logodesc py-4">
                At WEWAD, we believe in a future where everyone, regardless of
                gender, ability, age, background, tribe, or religion, is given
                the opportunity to thrive.
              </div>

              <div className="social-media-icons ">
                <a
                  href="https://web.facebook.com/WomenEmpoweringWomenAndDisabled"
                  className="social-media-icon"
                >
                  <i class="fa-brands fa-facebook-f"></i>
                </a>

                {/* <a
              href=""
                className="social-media-icon"
              >
                <i class="fa-brands fa-twitter"></i>
              </a> */}

                <a
                  href="https://www.youtube.com/@WEWADChannel"
                  className="social-media-icon"
                >
                  <i class="fa-brands fa-youtube"></i>
                </a>
              </div>
            </div>
            <div className="footersection2">
              <div className="footersection2title">Quick links</div>

              <div className="footersection2links pt-md-4 pt-2">
                <div className="pb-md-3 pb-1">
                  <a href="/" className="footersection2link">
                    <span className="footerarrow pe-1 ">
                      {" "}
                      <img src={footerarrow} alt="arrow" />{" "}
                    </span>{" "}
                    Home
                  </a>
                </div>
                <div className="pb-md-3 pb-1">
                  <a href="/about" className="footersection2link">
                    <span className="footerarrow pe-1 ">
                      {" "}
                      <img src={footerarrow} alt="arrow" />{" "}
                    </span>{" "}
                    About us
                  </a>
                </div>
                <div className="pb-md-3 pb-1">
                  <a href="/media" className="footersection2link">
                    <span className="footerarrow pe-1 ">
                      {" "}
                      <img src={footerarrow} alt="arrow" />{" "}
                    </span>{" "}
                    Media
                  </a>
                </div>
                <div className="pb-md-3 pb-1">
                  <a href="/blog" className="footersection2link">
                    <span className="footerarrow pe-1 ">
                      {" "}
                      <img src={footerarrow} alt="arrow" />{" "}
                    </span>{" "}
                    Blog
                  </a>
                </div>
                <div className="pb-3">
                  <a href="/contact" className="footersection2link">
                    <span className="footerarrow pe-1 ">
                      {" "}
                      <img src={footerarrow} alt="arrow" />{" "}
                    </span>{" "}
                    Contact
                  </a>
                </div>
              </div>
            </div>
            <div className="footersection2">
              <div className="footersection2title">Blog</div>

              <div className="footersection2links pt-md-4 pt-2">
                <div className="footersection2link">
                  <span className="footerarrow pe-1 ">
                    {" "}
                    <img src={footerarrow} alt="arrow" />{" "}
                  </span>{" "}
                  Supporting People Around You: Why It Matters...{" "}
                </div>
                <div className="footersection2link">
                  <span className="footerarrow pe-1 ">
                    {" "}
                    <img src={footerarrow} alt="arrow" />{" "}
                  </span>{" "}
                  The Ripple Effect of Giving: How Small Acts...
                </div>
                <div className="footersection2link">
                  <span className="footerarrow pe-1 ">
                    {" "}
                    <img src={footerarrow} alt="arrow" />{" "}
                  </span>{" "}
                  The Art of Giving: More Than Just a Gesture
                </div>
              </div>
            </div>
            <div className="footersection2">
              <div className="footersection2title">Contact</div>

              <div className="footersection2links pt-md-4 pt-2">
                <div className="footersection2link">
                  <span className="footerarrow pe-1 ">
                    <i class="fa-solid fa-location-dot"></i>
                  </span>{" "}
                  8530 Eagle point Blvd, suite 100, Lake Elmo, MN, United
                  States, Minnesota{" "}
                </div>
                <div className="footersection2link">
                  <span className="footerarrow pe-1 ">
                    <i class="fa-solid fa-location-dot"></i>
                  </span>{" "}
                  54, Makoko Road, Opp Olaiya Mosque, Makoko, Yaba, Lagos State Nigeria.
                </div>
                <Link
                  to={`mailto:wewadfoundation@gmail.com?subject=Interested%20In%20Donations&body=Hello!%20I'm%20interested%20in...`}
                  className="footersection2link"
                >
                  <span className="footerarrow pe-1 ">
                    {" "}
                    <i class="fa-solid fa-envelope"></i>
                  </span>{" "}
                  wewadfoundation@gmail.com
                  {" "}
                </Link>
                <div className="footersection2link pt-3">
                  <span className="footerarrow pe-1 ">
                    {" "}
                    <i class="fa-solid fa-phone-volume"></i>
                  </span>{" "}
                  <a className="tellink" href="tel:+19295879945">
                    +1 (929) 587-9945
                  </a>
                </div>
                {/* <div className="footersection2link pt-1">
                  <span className="footerarrow pe-1 ">
                    {" "}
                    <i class="fa-solid fa-phone-volume"></i>
                  </span>{" "}
                  <a className="tellink" href="tel:+2347071037325">
                    +234 707 103 7325
                  </a>
                </div> */}
                {/* <div className="footersection2link pt-1">
                  <span className="footerarrow pe-1 ">
                    {" "}
                    <i class="fa-solid fa-phone-volume"></i>
                  </span>{" "}
                  <a className="tellink" href="tel:+2348055474918">
                  +234 805 547 4918
                  </a>
                </div> */}
              </div>
            </div>
          </div>
          <div className="copyright  text-center ">
            <div>Copyright © 2024 Women Empowering Women And Disabled.</div>
          </div>
        </footer>
      </footer>
    </div>
  );
}

export default Footer;
